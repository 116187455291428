import './AuditTrailsTable.css';
import { getAuditTrailsDetail } from '../../../services/vidcredentials';
import React, { useContext, useState } from 'react';

import eyeSelectedIcon from '../../../assets/eyeSelectedIcon.svg';

import MaterialTable from 'material-table';
import { AuditTrailsDetailsModal } from '../AuditTrailsDetailsModal/AuditTrailsDetailsModal';
import { AuditTrailsContext } from '../../../contexts/AuditTrailsContext';
import { AuditTrailDetail, AuditTrailsList } from '../../../domain/auditTrails';
import { I18n } from '../../../i18n/i18n';
import TooltipItem from '../../../components/ToolTipItem/ToolTipItem';

export function AuditTrailsTable() {
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);
  const { auditTrails } = useContext(AuditTrailsContext);
  const [auditTrail, setAuditTrail] = useState({} as AuditTrailDetail);

  const openAuditTrail = async (id: string) => {
    try {
      const auditTrailResponse = await getAuditTrailsDetail(id);
      setAuditTrail(auditTrailResponse as AuditTrailDetail);
      setDetailsModalOpen(true);
    } catch (error) {
      console.log('Error unable to get the auditTrail');
      return;
    }
  };

  const formatDate = (date: string | undefined): string => {
    if (!date) {
      return I18n.t('table.notAvailable');
    }
    const formattedDate = new Date(date);
    return formattedDate.toLocaleString();
  };

  const tableColumns = [
    {
      title: I18n.t('filters.entityName'),
      field: 'entityName',
      render: (data: AuditTrailsList) => {
        return data.entityName;
      },
    },
    {
      title: I18n.t('table.agentName'),
      field: 'agent',
      render: (data: AuditTrailsList) => {
        return data.operator || I18n.t('table.notAvailable');
      },
    },
    {
      title: I18n.t('filters.actionName'),
      field: 'action',
      render: (data: AuditTrailsList) => {
        return `${I18n.t(`auditTrailsActions.${data.action}`)}`;
      },
    },
    {
      title: I18n.t('table.date'),
      field: 'date',
      render: (data: AuditTrailsList) => {
        return formatDate(data.date);
      },
    },
    {
      title: '',
      field: '',
      render: (data: AuditTrailsList) => {
        return (
          <button
            className="imageButton"
            onClick={() => openAuditTrail(data.eventId)}
          >
            <TooltipItem
              id={'detail' + data.eventId}
              title={I18n.t('table.viewDetails')}
            >
              <img src={eyeSelectedIcon} height={30} width={30} />
            </TooltipItem>
          </button>
        );
      },
    },
  ];

  return (
    <div>
      <AuditTrailsDetailsModal
        auditTrail={auditTrail}
        isOpen={isDetailsModalOpen}
        setIsOpen={setDetailsModalOpen}
      />
      <MaterialTable
        columns={tableColumns}
        data={auditTrails}
        title={''}
        options={{
          selection: false,
          search: false,
          pageSize: 10,
          headerStyle: {
            background: '#F9F9FB 0% 0% no-repeat padding-box',
            borderRadius: '6px 6px 0px 0px',
            opacity: 1,
            fontFamily: 'TT-Norms-Black',
          },
        }}
      />
    </div>
  );
}
