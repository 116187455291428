export default {
  InvalidUserRolException: 'Rol i pavlefshëm i përdoruesit',
  RoleEntityException: 'Rol i pavlefshëm i përdoruesit',
  RoleIssuerException: 'Rol i pavlefshëm i përdoruesit',
  InvalidUuidFormatException: 'Format i pavlefshëm uuid',
  InvalidISOFormatException: 'Format i pavlefshëm i datës',
  InvalidEmailFormatException: 'Format i pavlefshëm i emailit',
  InvalidLevelOfAssuranceException: 'Niveli i sigurisë nuk mbështetet',
  InvalidFieldPathException: 'Shtegu i pavlefshëm i fushës',
  InvalidCredentialSubjectValue:
    'Prona e dhënë e subjektit kredencial nuk përputhet me atë të kërkuar',
  InvalidCredentialSubjectProperty: 'Prona nuk u gjet në subjektin kredencial',
  DidNotFoundInJwt: 'DID nuk u gjet në JWT',
  InternalServerCustomError: 'Gabim i brendshëm',
  CredentialIdNotFoundException: 'Urdhëri kredencial nuk u gjet',
  CredentialOrderIdNotFoundException: 'Urdhëri kredencial nuk u gjet',
  CredentialOrdersByFilterNotFoundException:
    'Nuk ka kredenciale që përputhen me kërkimin tuaj',
  CredentialOrderNotFoundException: 'Nuk u gjet asnjë urdhër kredencialesh',
  AuthServiceValidationException: 'Qasja e paautorizuar e Entitetit',
  CredentialDuplicatedException: 'Kredenciali është lëshuar tashmë më parë.',
  CredentialPayloadValidatorException:
    'Dështoi vërtetimi i ngarkesës së kredencialeve',
  EntityDidNotValidException: 'Entiteti DID nuk është i vlefshëm',
  frontend_error_000: 'Gabim gjatë vërtetimit të përdoruesit:',
  frontend_error_001: 'Gabim gjatë krijimit të urdhrit të kredencialeve:',
  frontend_error_002: 'Gabim gjatë ngarkimit të të gjitha kredencialeve',
  frontend_error_003: 'Gabim gjatë lëshimit të kredencialit:',
  frontend_error_004: 'Gabim në marrjen e llojeve të kredencialeve: ',
  frontend_error_005: 'Gabim në marrjen e Nivelit të Sigurimeve:',
  frontend_error_006: 'Gabim në marrjen e llojit të Nivelit të Sigurisë: ',
  frontend_error_007: 'Gabim gjatë marrjes së Entiteteve’',
  frontend_error_008: "Gabim gjatë ridërgimit të emailit'",
  frontend_error_009: 'Gabim gjatë marrjes së kërkesës për kredenciale',
  login: {
    control:
      'Kontrollo dhe lësho kredenciale të verifikueshme në çdo kohë dhe nga kudo',
    with_vidchain:
      'Me VIDchain, ne ofrojmë zgjidhje të sigurta identiteti që lejojnë një botë më digjitale dhe të qëndrueshme, duke garantuar privatësi, të drejta dhe liri për njerëzit.',
    login_with: 'Identifikohu me VIDwallet',
    more_info: 'Më shumë informacion rreth VIDwallet',
    successful: 'Identifikimi ishte i suksesshëm',
    failed: 'Procesi i identifikimit dështoi',
    redirected: 'Do të ridrejtoheni te ekrani kryesor.',
    try_again:
      'Ju lutemi provoni përsëri ose kontaktoni administratorin e faqes tuaj.',
    continueIssuance:
      'Klikoni në butonin e identifikimit për të vazhduar me procesin e lëshimit të kredencialeve',
    redirecting: 'Po ridrejtoheni për autentifikim…',
    get_it: 'Merreni falas në telefonin tuaj',
  },
  callback: {
    success: 'Procesi i lëshimit të kredenciales ishte i suksesshëm.',
    mobile_wallet:
      'Kredenciali u lëshua. Hapni VIDwallet për të marrë kredencialet',
    go_wallet: 'Hapni VIDwallet për të kontrolluar kredencialet.',
    open_wallet: 'Hapni VIDwallet',
    failed: 'Procesi i lëshimit të kredencialeve dështoi',
    error_details: 'Detajet e gabimit:',
  },
  actions: {
    cancel: 'Anulo',
    filter: 'Filtro',
    accept: 'Prano',
    success: 'Sukses',
    confirm: 'Konfirmo',
    search: 'Kërko',
    create: 'Krijo',
    revoke: 'Shfuqizo',
    resend: 'Ridërgo',
    edit: 'Ndrysho',
    close: 'Mbyll',
    save: 'Ruaj',
    approve: 'Aprovo',
    reject: 'Refuzo',
    sendToSign: 'Dërgo për të nënshkruar',
    ok: 'Në rregull',
  },
  form: {
    fill: 'Plotësoni formularin',
    receiverEmail: 'Email-i i pranuesit',
    identityVerification: 'Mënyra e verifikimit të identitetit',
    loadingCredential: 'Po ngarkohen të dhënat e llojit të kredencialeve...',
    loadingIdentity: 'Po ngarkohen të dhënat e verifikimit të identitetit...',
    createNewUser: 'Krijo përdorues të ri',
    fillfield: 'Duhet të plotësoni këtë fushë',
    provideValidEmail: 'Duhet të jepni një email të vlefshëm',
    provideValidNumber: 'Duhet të jepni një numër personal të vlefshëm',
    provideValidURL: 'Duhet të jepni një URL të vlefshme',
    provideValidDate: 'Duhet të jepni një datë të vlefshme',
    credentialCreated:
      'Urdhri kredencial u krijua me sukses. Përdoruesi do të marrë një email për të marrë kredencialet',
    credentialF2FCreated:
      'Urdhri i kredencialeve u krijua me sukses. Ju lutemi nënshkruani dokumentin në pajisjen %%%%% për të përfunduar procesin',
    credentialSubject: 'Subjekti i kredencialeve',
    language: 'Gjuha',
    chooseLanguage: 'Zgjidh një gjuhë...',
    credentialUpdated: 'Kërkesa për kredencialet u përditësua me sukses',
    userLanguage: 'Gjuha e përdoruesit',
  },
  filters: {
    any: 'Cilindo atribut në kredencial',
    credentialType: 'Lloji i kredencialit',
    startDate: 'Data e fillimit',
    endDate: 'Data e përfundimit',
    actionName: 'Emri i Veprimit',
    entityName: 'Emri i Entitetit',
    selectEntity: 'Zgjidh një entitet',
    searchText: 'Kërko tekstin',
    types: 'Lloji',
    status: 'Statusi',
  },
  table: {
    creationDate: 'Data e Krijimit',
    notAvailable: 'N/A',
    sureRevoke: 'A je i sigurt që dëshiron të shfuqizosh këtë kredencial?',
    sureReject:
      'Je i sigurt që dëshiron ta refuzosh këtë kërkesë për kredenciale?',
    agentName: 'Emri i Agjentit',
    date: 'Data',
    status: 'Statusi',
    viewDetails: 'Shiko detajet',
    revokeCredential: 'Shfuqizo kredencialet',
    resendEmail: 'Ridërgo emailin',
    ivmStatus: 'Statusi i Metodës së Verifikimit të Identitetit',
    expirationDate: 'Data e skadimit',
  },
  menu: {
    credentials: 'Menaxhimi i kredencialeve',
    issueCredentials: 'Lëshoni kredenciale',
    user: 'Menaxhimi i përdoruesit',
    auditTrails: 'Gjurmë auditimi',
    credentialRequests: 'Kërkesat për kredenciale',
  },
  details: {
    attachments: 'Shtojcat',
    auditTrailsDetails: 'Detaje të provave të auditimit',
    name: 'Emri',
    eventName: 'Emri i ngjarjes',
    operatorName: 'Emri i agjentit',
    entity: 'Entitet',
    operator: 'Agjent',
    role: 'Roli',
    legalId: 'ID Ligjor',
    action: 'Veprim',
    payload: 'Ngarkesë',
    emailResentSuccess: 'Emaili është ridërguar me sukses',
    revokeSuccess: 'Kërkesa për shfuqizim u krye me sukses.',
    rejectSuccess: 'Kërkesa për kredenciale u refuzua',
    credentialDetails: 'Detajet e kredencialeve',
    userDetails: 'Detajet e përdoruesit',
    requestDetails: 'Detajet e Kërkesës',
    credentialRequestDetails: 'Kërkesë për kredenciale',
    credentialOrderId: 'ID e Urdhrit Kredencial',
    credentialIssuanceId: 'ID e Lëshimit të Kredencialeve',
    type: 'Lloji i kredencialit',
    types: 'Lloji',
    issuer: 'Lëshuesi',
    holder: 'Mbajtësi',
    createdAt: 'Krijuar në',
    issuedAt: 'Lëshuar në',
    id: 'ID',
    test: 'Test',
    receiverEmail: 'Email-i i pranuesit',
    identityVerificationMethod: 'Metoda e verifikimit të identitetit',
    faceToFace: 'Ballë për ballë',
    next: 'Vazhdo',
    previous: 'Prapa',
    stillNotSigned:
      'Dokumenti ende nuk është nënshkruar. Nënshkruaje dokumentin në pajisje dhe pastaj kliko butonin Vazhdo.',
    credentialIssuanceDocumentSignature:
      'Nënshkrimi i dokumentit të lëshimit të kredencialeve',
    selectDevice:
      'Zgjidhni një pajisje ku të dërgoni dokumentin për ta nënshkruar:',
    signedDocument:
      'Një email është dërguar te përdoruesi për të përfunduar procesin.',
    docHasBeenSent:
      'Nënshkruaje dokumentin në pajisje dhe pastaj kliko butonin Vazhdo.',
    docHasBeenRejected: 'Dokumenti është refuzuar',
    sent: 'Dokumenti është dërguar',
    signed: 'Dokumenti është nënshkruar',
    credentialOrderCreatedFromRequest:
      'Kërkesa juaj për kredenciale është aprovuar. Shkoni te menaxhimi i kredencialeve.',
    credentialRequestApproved: 'Kërkesa për kredenciale u aprovua',
  },
  credentialRequestApprovedModal: {},
  requestDetails: {
    credentialRequestId: 'ID e Kërkesës për Kredenciale',
    type: 'Lloji i kredencialit',
    receiverEmail: 'Email-i i pranuesit',
    requestedAt: 'Kërkuar në',
    identityVerificationMethod: 'Mënyra e verifikimit të identitetit',
    F2F: 'Ballë për ballë',
    userDetails: 'Detajet e përdoruesit',
    EmailCredential: 'Kredencialet e emailit',
    VidKycCredential: 'KYC',
  },
  identityVerificationMethod: {
    userName: 'Emri i plotë',
    userLegalId: 'ID Ligjor',
  },
  status: {
    CREATED: 'Krijuar',
    ISSUED: 'Lëshuar',
    REVOKED: 'Shfuqizuar',
    REVOCATION_REQUESTED: 'Shfuqizim i kërkuar',
    undefined: 'E papërcaktuar',
    REQUESTED: 'Kërkuar',
    VERIFIED: 'Verifikuar',
    UNVERIFIED: 'Jo i verifikuar',
    ISSUE_REQUESTED: 'Kërkesë për lëshim',
    REVOCATION_FAILED: 'Shfuqizimi dështoi',
  },
  requestStatus: {
    REQUESTED: 'Kërkuar',
    APPROVED: 'Aprovuar',
  },
  identityVerificationStatus: {
    CREATED: 'Krijuar',
    STARTED: 'Filluar',
    DOCUMENT_REJECTED: 'Dokumenti u refuzua',
    DOCUMENT_SENT: 'Dokumenti u dërgua',
    FINISHED: 'Përfunduar',
  },
  auditTrailsActions: {
    UserLogin: 'IdentifikimiIPërdoruesit',
    CredentialOrderCreated:
      'Urdhri kredencial u krijua me sukses. Përdoruesi do të marrë një email për të marrë kredencialet',
    ResendCredentialOrderEmail: 'RidërgimiIEmailitTëUrdhritTëKredencialeve',
    CredentialHolderAuthenticated: 'MbajtësiIAutentifikuarIKredencialeve',
    CredentialIssued: 'KredencialiILeshuar',
    CredentialRevocationRequested: 'KërkesëPërShfuqiziminEKredencialeve',
    CredentialRevoked: 'KredencialIShfuqizuar',
    CredentialRequestCreated: 'KërkesëPërUrdhërKredencialeKrijuar',
    CredentialOrderRequestCreated: 'KërkesëPërUrdhërKredencialeKrijuar',
    CredentialRequestConfirmed: 'KërkesaPërKredencialeEKonfirmuar',
    CredentialRequestDocumentSigned:
      'DokumentiIKërkesësPërKredencialeINënshkruar',
    CredentialOrderRequestDeleted: 'KërkesëUrdhërKredencialeEFshirë',
    CredentialMigrated: 'KredencialIMigruar',
  },
};
