import { useContext } from 'react';
import { changeLanguage } from '../../i18n/i18n';
import { AuthContext } from '../../contexts/AuthContext';

export const LanguageSwitch = () => {
  const { locale } = useContext(AuthContext);

  return (
    <select
      className="language-select"
      value={locale}
      onChange={(e) => changeLanguage(e.target.value)}
    >
      <option value="sr">Srpski</option>
      <option value="sq">Shqip</option>
      <option value="en">English</option>
    </select>
  );
};
