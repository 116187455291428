export default {
  InvalidUserRolException: 'Nevažeća uloga korisnika',
  RoleEntityException: 'Nevažeća uloga korisnika',
  RoleIssuerException: 'Nevažeća uloga korisnika',
  InvalidUuidFormatException: 'Nevažeći uuid format',
  InvalidISOFormatException: 'Nevažeći format datuma',
  InvalidEmailFormatException: 'Nevažeći email format',
  InvalidLevelOfAssuranceException: 'Nivo sigurnosti nije podržan',
  InvalidFieldPathException: 'Nevažeća putanja polja',
  InvalidCredentialSubjectValue:
    'Navedena predmetna svojina akreditiva se ne poklapa sa zahtevanim',
  InvalidCredentialSubjectProperty:
    'Svojina nije pronađena u predmetu akreditiva',
  DidNotFoundInJwt: 'DID nije pronađen u JWT-u',
  InternalServerCustomError: 'Unutrašnja greška',
  CredentialIdNotFoundException: 'Redosled akreditiva nije pronađen',
  CredentialOrderIdNotFoundException: 'Redosled akreditiva nije pronađen',
  CredentialOrdersByFilterNotFoundException:
    'Nema akreditiva koji odgovara vašem pronalasku',
  CredentialOrderNotFoundException: 'Nije pronađen nijedan nalog za akreditive',
  AuthServiceValidationException: 'Neovlašćeni pristup entitetu',
  CredentialDuplicatedException: 'Akreditacija je već ranije izdata',
  CredentialPayloadValidatorException:
    'Neuspela validacija nosivosti akreditiva',
  EntityDidNotValidException: 'Entitet DID nije validan',
  frontend_error_000: 'Greška pri autentifikaciji korisnika',
  frontend_error_001: 'Greška pri kreiranju naloga za akreditaciju',
  frontend_error_002: 'Greška pri učitavanju svih akreditiva',
  frontend_error_003: 'Greška pri izdavanju akreditiva',
  frontend_error_004: 'Greška pri dobijanju tipova akreditiva',
  frontend_error_005: 'Greška pri dobijanju nivoa garancije',
  frontend_error_006: 'Greška pri dobijanju tipa nivoa sigurnosti',
  frontend_error_007: 'Greška pri preuzimanju entiteta',
  frontend_error_008: 'Greška pri ponovnom slanju emaila',
  frontend_error_009: 'Greška pri preuzimanju zahteva za akreditive',
  login: {
    control:
      'Kontrola i izdavanje poverljivih akreditiva u bilo koje vreme i sa bilo kog mesta',
    with_vidchain:
      'Sa VIDchain-om nudimo bezbedna rešenja identiteta koja omogućavaju digitalniji i održiviji svet koji ljudima garantuje privatnost, prava i slobode',
    login_with: 'Prijavite se sa VIDnovčanik-om',
    more_info: 'Više informacija o VIDwallet',
    successful: 'Prijava je uspela',
    failed: 'Proces prijave nije uspeo',
    redirected: 'Bićete preusmereni na početni ekran',
    try_again: 'Probajte ponovo ili kontaktirajte administrator vašeg sajta',
    continueIssuance:
      'Kliknite na dugme za prijavu da biste nastavili sa procesom izdavanja akreditiva',
    redirecting: 'Preusmeravanje radi autentifikacije',
    get_it: 'Nabavite besplatno na svom telefonu',
  },
  callback: {
    success: 'Proces izdavanja akreditiva je bio uspešan',
    mobile_wallet:
      'Akreditacija izdata. Otvorite VIDnovčanik da biste primili akreditive',
    go_wallet: 'Otvorite VIDnovčanik da biste proverili akreditive',
    open_wallet: 'Otvorite VIDnovčanik',
    failed: 'Proces izdavanja akreditiva nije uspeo',
    error_details: 'Detalji greške',
  },
  actions: {
    cancel: 'Poništiti',
    filter: 'Filtriraj',
    accept: 'Prihvatiti',
    success: 'Uspeh',
    confirm: 'Potvrditi',
    search: 'Pretražiti',
    create: 'Kreiraj',
    revoke: 'Opozvati',
    resend: 'Ponovo pošalji',
    edit: 'Urediti',
    close: 'Zatvoriti',
    save: 'Sačuvaj',
    approve: 'Odobriti',
    reject: 'Odbiti',
    sendToSign: 'Poslati na potpis',
    ok: 'Ok',
  },
  form: {
    fill: 'Popunite formular',
    receiverEmail: 'Email primaoca',
    identityVerification: 'Metoda verifikacije identiteta',
    loadingCredential: 'Učitavanje podataka o tipu akreditiva',
    loadingIdentity: 'Učitavanje podataka za verifikaciju identiteta',
    createNewUser: 'Kreiraj novog korisnika',
    fillfield: 'Morate popuniti ovo polje',
    provideValidEmail: 'Morate obezbediti ispravni email',
    provideValidNumber: 'Morate da navedete važeći lični broj',
    provideValidURL: 'Morate da navedete važeću URL adresu',
    provideValidDate: 'Morate da navedete važeći datum',
    credentialCreated:
      'Nalog akreditiva je uspešno kreiran. Korisnik će primiti email sa dobijenim akreditivima',
    credentialF2FCreated:
      'Nalog akreditiva je uspešno kreiran. Molimo potpište document na uređaju %%%%% da biste završili proces',
    credentialSubject: 'Predmet akreditiva',
    language: 'Jezik',
    chooseLanguage: 'Izaberite jezik…',
    credentialUpdated: 'Zahtev akreditiva je uspešno ažuriran',
    userLanguage: 'Korisnički jezik',
  },
  filters: {
    any: 'Bilo koji atribut u akreditivima',
    credentialType: 'Vrsta akreditiva',
    startDate: 'Datum početka',
    endDate: 'Krajnji datum',
    actionName: 'Naziv radnje',
    entityName: 'Ime entiteta',
    selectEntity: 'Izaberite entitet',
    searchText: 'Pretraži tekst',
    types: 'Tip/vrsta',
    status: 'Status',
  },
  table: {
    creationDate: 'Datum izrade',
    notAvailable: 'N/A',
    sureRevoke: 'Da li ste sigurni da želite da opozovete ovaj akreditiv?',
    sureReject:
      'Da li ste sigurni da želite da odbijete ovaj zahtev za akreditaciju?',
    agentName: 'Ime agenta',
    date: 'Datum',
    status: 'Status',
    viewDetails: 'Prikaz detalja',
    revokeCredential: 'Opozovi akreditiv',
    resendEmail: 'Ponovo pošalji email',
    ivmStatus: 'Status metode verifikacije identiteta',
    expirationDate: 'Rok trajanja',
  },
  menu: {
    credentials: 'Upravljanje akreditivima',
    issueCredentials: 'Idaj akreditive',
    user: 'Upravljanje korisnicima',
    auditTrails: 'Revizija ruta',
    credentialRequests: 'Zahtevi za akreditive',
  },
  details: {
    attachments: 'Prilozi',
    auditTrailsDetails: 'Detalji revizijske staze',
    name: 'Naziv',
    eventName: 'Naziv događaja',
    operatorName: 'Ime agenta',
    entity: 'Entitet',
    operator: 'Agent',
    role: 'Uloga',
    legalId: 'Pravni identitet',
    action: 'Postupak',
    payload: 'Korisni teret',
    emailResentSuccess: 'Email je ponovo poslat',
    revokeSuccess: 'Zahtev za opoziv je uspeo',
    rejectSuccess: 'Zahtev akreditiva je odbijen',
    credentialDetails: 'Detalji akreditiva',
    userDetails: 'Detalji o korisniku',
    requestDetails: 'Zahtevaj detalje',
    credentialRequestDetails: 'Zahtev za akreditiv',
    credentialOrderId: 'ID naloga akreditiva',
    credentialIssuanceId: 'ID izdavanja akreditiva',
    type: 'Vrsta/tip akreditiva',
    types: 'Vrsta/tip',
    issuer: 'Izdavaoc',
    holder: 'Nosioc',
    createdAt: 'Kreirano u',
    issuedAt: 'Izdato u',
    id: 'ID',
    test: 'Test',
    receiverEmail: 'E-mail primaoca',
    identityVerificationMethod: 'Metoda verifikacije identiteta',
    faceToFace: 'Lice u lice',
    next: 'Dalje',
    previous: 'Prethodno',
    stillNotSigned:
      'Dokument još uvek nije potpisan. Potpišite document na uređaju, a zatim kliknite na dugme ‘Dalje’',
    credentialIssuanceDocumentSignature:
      'Potpis dokumenta za izdavanje akreditiva',
    selectDevice:
      'Izaberite uređaj na koji želite da pošaljete document na potpisivanje',
    signedDocument: 'Korisniku je poslat e-mail da zvrši proces',
    docHasBeenSent:
      'Potpišite document na uređaju, a zatim kliknite na dugme ‘Dalje’',
    docHasBeenRejected: 'Dokument odbijen',
    sent: 'Dokument poslat',
    signed: 'Dokument potpisan',
    credentialOrderCreatedFromRequest:
      'Vaš zahtev za akreditaciju je odobren. Idite na upravljanje akreditivima',
    credentialRequestApproved: 'Zahtev akreditiva je odobren',
  },
  credentialRequestApprovedModal: {},
  requestDetails: {
    credentialRequestId: 'Identifikacija zahteva akreditiva',
    type: 'Vrsta akreditiva',
    receiverEmail: 'E-mail primaoca',
    requestedAt: 'Zatraženo u',
    identityVerificationMethod: 'Metoda verifikacije identiteta',
    F2F: 'Lice u lice',
    userDetails: 'Detalji korisnika',
    EmailCredential: 'Akreditiv e-maila',
    VidKycCredential: 'ZSK',
  },
  identityVerificationMethod: {
    userName: 'Puno ime',
    userLegalId: 'Pravna identifikacija',
  },
  status: {
    CREATED: 'Stvoreno',
    ISSUED: 'Izdato',
    REVOKED: 'Opozvano',
    REVOCATION_REQUESTED: 'Opozvano zatraženo',
    undefined: 'Nedefinisano',
    REQUESTED: 'Traženo',
    VERIFIED: 'Provereno',
    UNVERIFIED: 'Neprovereno',
    ISSUE_REQUESTED: 'Traženo pitanje',
    REVOCATION_FAILED: 'Opoziv nije uspeo',
  },
  requestStatus: {
    REQUESTED: 'Traženo',
    APPROVED: 'Odobreno',
  },
  identityVerificationStatus: {
    CREATED: 'Stvoreno',
    STARTED: 'Započeto',
    DOCUMENT_REJECTED: 'Dokument odbijen',
    DOCUMENT_SENT: 'Dokument poslat',
    FINISHED: 'Završen',
  },
  auditTrailsActions: {
    UserLogin: 'Prijavazakorisnike',
    CredentialOrderCreated: 'Nalogzaakreditacijujekreiran',
    ResendCredentialOrderEmail: 'Ponovopošaljiemailsanalogomzaakreditive',
    CredentialHolderAuthenticated: 'Nosiocakreditivajepotvrđen',
    CredentialIssued: 'Izdatakreditiv',
    CredentialRevocationRequested: 'Zatraženopozivakreditiva',
    CredentialRevoked: 'Akreditivopozvan',
    CredentialRequestCreated: 'Kreiranzahtevzaakreditive',
    CredentialOrderRequestCreated: 'Kreiranzahtevzanalogzaizdavanjeakreditiva',
    CredentialRequestConfirmed: 'Zahtevzaakreditacijupotvrđen',
    CredentialRequestDocumentSigned: 'Potpisandokumentzahtevazaakreditaciju',
    CredentialOrderRequestDeleted:
      'Zahtevzanalogzaizdavanjeakreditivajeizbrisan',
    CredentialMigrated: 'Akreditivmigrirao',
  },
};
