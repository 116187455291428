import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginCallback.css';
import background from '../../assets/background.svg';
import errorImage from '../../assets/close-browser-error.svg';
import { Container } from 'reactstrap';
import { Header } from '../../components/Header/Header';
import { SuccessMessage } from '../../components/SuccessMessage/SuccessMessage';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import { authLogin } from '../../services/vidcredentials';
import axiosInstance from '../../services/axiosInstance';
import { AuthContext } from '../../contexts/AuthContext';
import { Spinner } from '../../components/Spinner/Spinner';
import { I18n } from '../../i18n/i18n';
import { TOKEN_REFRESH_TIME } from '../../domain/refreshToken';
import { useRefreshToken } from '../../hooks/useRefreshToken';

export function LoginCallback() {
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [hasBeenSuccess, setHasBeenSuccess] = useState(false);
  const [hasBeenError, setHasBeenError] = useState(false);

  const tokenProcess = useRefreshToken();

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code');
    if (!code) {
      console.log('Error in the login process');
      setHasBeenError(true);
      return;
    }
    console.log('Callback code: ', code);

    const login = async () => {
      try {
        const sessionToken = await authLogin(code);
        if ('error' in sessionToken) {
          setHasBeenError(true);
          setLoading(false);
          return;
        }
        setLoading(false);
        setHasBeenSuccess(true);
        const accessToken = sessionToken.access_token;

        axiosInstance.defaults.headers.common['Authorization'] =
          'Bearer ' + accessToken;
        setToken(accessToken);

        navigate('/credentials');
      } catch (error) {
        setLoading(false);
        console.log('Error unable to login');
        setHasBeenError(true);
        return;
      }
    };
    login();

    const id = setInterval(() => {
      tokenProcess.refreshTokenProcess();
    }, TOKEN_REFRESH_TIME);

    localStorage.setItem('intervalId', id as unknown as string);
  }, []);
  return (
    <>
      <Container>
        <Header />
        <section className="home-container">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="info-column">
                {hasBeenSuccess && (
                  <SuccessMessage
                    messageTitle={I18n.t('login.successful')}
                    message={I18n.t('login.redirected')}
                  />
                )}
                {hasBeenError && (
                  <ErrorMessage
                    messageTitle={I18n.t('login.failed')}
                    message={I18n.t('login.try_again')}
                  />
                )}
                <div className="vid-chain-buttons-container">
                  {hasBeenSuccess && (
                    <a
                      className="sign-in-vid-chain"
                      href="https://try.vidchain.net/demo/"
                    >
                      <span>{I18n.t('login.more_info')}</span>
                      <span>&rarr;</span>
                    </a>
                  )}
                </div>
              </div>
              <div className="background-column">
                <img
                  className={hasBeenSuccess ? 'success-image' : 'error-image'}
                  src={hasBeenSuccess ? background : errorImage}
                  alt="Background"
                />
              </div>
            </>
          )}
        </section>
      </Container>
    </>
  );
}
