import { Fragment, useContext } from 'react';
import { Button, Input, InputGroup } from 'reactstrap';
import { CredentialsContext } from '../../../contexts/CredentialsContext';
import './AdvancedFilterModal.css';

import { I18n } from '../../../i18n/i18n';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function AdvancedFilterModal(props: Props) {
  const { filters, setFilters, getCredentialsByFilters, credentialTypes } =
    useContext(CredentialsContext);

  const handleClose = () => {
    setFilters({
      types: '',
      did: '',
      searchableText: '',
      status: '',
    });
    props.setIsOpen(false);
  };

  const applyFilter = () => {
    getCredentialsByFilters();
    props.setIsOpen(false);
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <Fragment>
      <InputGroup className="input-finder-advanced-search-bar">
        <Input
          id="exampleSelect"
          placeholder={I18n.t('table.status')}
          name="status"
          value={filters.status}
          type="select"
          onChange={handleChange}
        >
          <option value="">-- {I18n.t('table.status')} --</option>
          <option value="CREATED">{I18n.t('status.CREATED')}</option>
          <option value="ISSUED">{I18n.t('status.ISSUED')}</option>
          <option value="REVOKED">{I18n.t('status.REVOKED')}</option>
          <option value="REVOCATION_REQUESTED">
            {I18n.t('status.REVOCATION_REQUESTED')}
          </option>
          <option value="VERIFIED">{I18n.t('status.VERIFIED')}</option>
          <option value="UNVERIFIED">{I18n.t('status.UNVERIFIED')}</option>
          <option value="ISSUE_REQUESTED">
            {I18n.t('status.ISSUE_REQUESTED')}
          </option>
          <option value="REVOKED_FAILED">
            {I18n.t('status.REVOKED_FAILED')}
          </option>
        </Input>
      </InputGroup>
      <InputGroup className="input-finder-advanced-search-bar">
        <Input
          id="exampleSelect"
          placeholder={I18n.t('filters.credentialType')}
          name="types"
          value={filters.types}
          type="select"
          onChange={handleChange}
        >
          <option value="">-- {I18n.t('filters.credentialType')} --</option>
          {credentialTypes.map((option) => (
            <option key={option.id}>{option.name}</option>
          ))}
        </Input>
      </InputGroup>
      <InputGroup className="input-finder-advanced-search-bar">
        <Input
          placeholder="DID"
          name="did"
          value={filters.did}
          onChange={handleChange}
        />{' '}
      </InputGroup>
      <InputGroup className="input-finder-advanced-search-bar">
        <Input
          placeholder={I18n.t('filters.any')}
          name="searchableText"
          value={filters.searchableText}
          onChange={handleChange}
        />{' '}
      </InputGroup>
      <Button
        className="buttonCancel"
        variant="secondary"
        onClick={handleClose}
      >
        {I18n.t('actions.cancel')}
      </Button>
      <Button
        className="buttonFilter"
        variant="secondary"
        onClick={applyFilter}
      >
        {I18n.t('actions.filter')}
      </Button>
    </Fragment>
  );
}
